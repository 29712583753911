import {environment} from 'src/environments/environment';

let uriHost = '/graphql';

if (environment.betaTest) {
  uriHost = 'https://test.graphql.serdoapi.com/';
}
if (environment.production) {
  uriHost = 'https://graphql2.serdoapi.com/';
}

uriHost = 'https://graphql2.serdoapi.com/graphql';
export const GRAPHQL_URI = uriHost;
export const WYSING_EDITOR_IMAGE_UPLOAD_URI = uriHost + 'upload';
export const REGISTERED_USER_ROLE = 'registered-user';
export const UNREGISTERED_USER_ROLE = 'unregistered-user';
export const ADMIN_ROLE = 'admin';
export const SUPER_ADMIN_ROLE = 'super-admin';

// Advertisement plan names
export const HOME_PAGE_TOP = 'Home page -  front';
export const HOME_PAGE_SIDE = 'Home page - side banner';

// Assets url
export const ASSETS_DIR = '/assets/';

// Ad ids

export const FUTURE_AD_ID = 'QWRQYWNrYWdlTm9kZTo0';
export const SERDO_GOAD_AD_ID = 'QWRQYWNrYWdlTm9kZTo2';
export const HOME_PAGE_AD = 'QWRQYWNrYWdlTm9kZTo4';
export const HOME_PAGE_GOALD_AD = 'QWRQYWNrYWdlTm9kZToxMg==';
export const HOME_PAGE_SILIVER_AD = 'QWRQYWNrYWdlTm9kZToxMw==';
export const HOME_PAGE_SIDE_AD = 'QWRQYWNrYWdlTm9kZTo5';
export const CATEGORY_PAGE_AD = 'QWRQYWNrYWdlTm9kZToxMA==';
export const PRODUCT_PAGE_AD = 'QWRQYWNrYWdlTm9kZToxNA==';
